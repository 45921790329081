import makeFAIcon from "utils/makeFAIcon";

import { faLeaf, faSquare, faCertificate, faTree, faTint, faShoppingCart, faLaptop, faLock } from "@fortawesome/free-solid-svg-icons";
import { faBitbucket } from "@fortawesome/free-brands-svg-icons";

export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const LaptopIcon = makeFAIcon(faLaptop);
export const LockIcon = makeFAIcon(faLock);

export const LeafIcon = makeFAIcon(faLeaf);
export const SquareIcon = makeFAIcon(faSquare);
export const TextileIcon = makeFAIcon(faCertificate);
export const TreeIcon = makeFAIcon(faTree);
export const TintIcon = makeFAIcon(faTint);
export const CementIcon = makeFAIcon(faBitbucket);
